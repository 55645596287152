/* Reset */
html, body, div, span, applet, object, iframe,  
h1, h2, h3, h4, h5, h6, p, blockquote, pre,  
a, abbr, acronym, address, big, cite, code,  
del, dfn, em, img, ins, kbd, q, s, samp,  
small, strike, strong, sub, sup, tt, var,  
b, u, i, center,  
dl, dt, dd, ol, ul, li,  
fieldset, form, label, legend,  
table, caption, tbody, tfoot, thead, tr, th, td,  
article, aside, canvas, details, embed,  
figure, figcaption, footer, header, hgroup,  
menu, nav, output, ruby, section, summary,  
time, mark, audio, video {  
margin: 0;  
padding: 0;  
border: 0;  
font-size: 100%;  
font: inherit;  
vertical-align: baseline;  
}

*{max-width: 100%;
box-sizing:border-box;
}

html{font-size:62.5%;}
/* Reset */

/* Snapping effect */

html, body {
    height: 100vh;
    overflow: hidden;
}

div .App{
    height: 100vh;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y proximity;
    overflow-y: scroll;
}

.snap{
    scroll-snap-align: start end;
}

/* Snapping effect */